.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
}

.scale {
  transform: scale(1.05);
  z-index: 1;
}

.video {
  cursor: pointer;
}

.media_back {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(223, 222, 222);
}

.video_card {
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  box-shadow: none;
}

.card_title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 10px !important;
}

.card_date {
  font-size: small;
}

.index {
  position: absolute;
  background-color: #1e40af;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  margin: -10px;
  font-size: 16px;
  padding: 20px;
  border: 2px solid;
 
}

.card {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: default;
}

.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.content {
  border-radius: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px;
}
