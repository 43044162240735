.custom_select {
  label {
    color: rgb(255, 255, 255) !important;
  }
}
.selected {
  display: flex !important;
  width: 100%;
  color:azure;
  .select_icon {
    display: none !important;
  }
  span{
      color: aliceblue;
  
  }
}