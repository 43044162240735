.add_video {
  width: 100%;
  height: 340px;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.add_video img {
  width: 120px;
}

.card {
  overflow-y: auto !important;
  max-height: 100vh;
}
.spinner {
  border: 6px solid rgb(20 20 20 / 30%);
  border-top: 6px solid #4caf50;
  border-radius: 50%;
  width: 13rem;
  height: 13rem;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.video_actions {
  display: flex;
  gap: 20px;
  background-color: #1a73e818;
  width: max-content;
  padding: 5px 20px;
  border-radius: 25px;
  .edit,
  .delete {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
  }

  .edit {
    background-color: #1a73e8;
  }

  .delete {
    background-color: #f44335;
  }
}


