.react-player {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 12px;
  overflow: hidden;
  max-height: 12.7rem;
  display: flex;
}

/* @media only screen and (max-width: 1977px) and (min-width: 1200px)  {.react-player{
    max-height: 13.4rem;
}}*/
@media only screen and (max-width: 1900px) and (min-width: 1700px) {
  .react-player {
    max-height: 11rem;
  }
}
@media only screen and (max-width: 1700px) and (min-width: 1600px) {
  .react-player {
    max-height: 10rem;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1480px) {
  .react-player {
    max-height: 9rem;
  }
}
@media only screen and (max-width: 1480px) and (min-width: 1360px) {
  .react-player {
    max-height: 8rem;
  }
}
@media only screen and (max-width: 1360px) and (min-width: 1000px) {
  .react-player {
    max-height: 7rem;
  }
}
@media only screen and (max-width: 3000px) and (min-width: 1900px) {
  .react-player {
    max-height: 12.6rem;
  }
}
.react-player video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
.shadow {
  width: 100%;
  height: 5rem; /* Adjust height as needed */
  z-index: 50;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
  position: absolute;
  top: 0;
  pointer-events: none;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 16px;
}

.icons {
  position: absolute;
  z-index: 50;
  display: flex;
  width: 4rem;
  justify-content: space-between;
  color: white;
  top: 1.3rem;
  right: 12px;
}
.isFullScreen video {
  height: 100vh !important;
  position: absolute;
}
.icon_custom {
  cursor: pointer;
}
.quality {
  position: absolute;
  z-index: 30;
  top: 3rem;
  right: 3rem;
  background-color: #4a5568;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06); /* Shadow */

  width: 5rem;
  padding-top: 0.25rem;
}

/* Styles for the .qualitybutton class */
.qualitybutton {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  border: none;
  background: transparent;

  color: #fff;
  transition: color 0.3s ease;
}

.qualitybutton:hover,
.qualitybutton.text-yellow-500 {
  color: #f59e0b;
}
.full-screen {
  margin-left: 1.5rem;
}
