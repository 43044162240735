
.calendarWrap {
    display: inline-block;
    position: relative;
}
  
.calendarElement {
    position: absolute;
    transform: translateX(-50%);
    border-radius: 4px;
    top: 3rem;
    left: -10rem;
    border: 1px solid #ccc;
    z-index: 1999;
    margin-bottom: 9rem !important;
}
@media only screen and (max-width: 1200px) {
    .calendarElement {
      left: 0rem;
    }
  }
