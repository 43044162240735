.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
}

.scale {
  transform: scale(1.05);
  z-index: 1;
}

.video {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  cursor: pointer;
}

.media_back {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  // background-image: url("/assets/images/video.svg");
  background-size: contain;
  // border-radius: 16px;
  background-repeat: no-repeat;
  background-position: center;
  // background-color: rgb(223, 222, 222);
  height: 400px;
}

.video_card {
  border-radius: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  box-shadow: none;
}

.card_title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 10px !important;
}

.card_date {
  font-size: small;
}

.index {
  position: absolute;
  background-color: #1e40af;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  margin: -10px;
  font-size: 16px;
  padding: 20px;
  border: 2px solid;
 
}

.card {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: default;
}

.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.content {
  border-radius: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px;
}

.show_for_5s {
  opacity: 1;
  animation: hideText 10s ease forwards;
  @keyframes hideText {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1; 
    }
    100% {
      opacity: 0;
    }
  }
}
