//multiple-date-range

.multiple-date-range {
  display: grid;
  grid-template-columns: 1fr 5fr 5fr;
  align-items: center;
}

.multiple-date-range .rdt .rdtDays {
  font-size: small;
}

.multiple-date-range .rdt .rdtDay {
  border-radius: 50%;
  padding: 5px;
}

.multiple-date-range .rdt input {
  font-size: small;
  padding: 13.5px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  width: 100%;
}

.multiple-date-range .mdt-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 21.2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
}

.multiple-date-range .mdt-input-left input {
  border-radius: 0px;
}

.multiple-date-range .mdt-input-right input {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

//// input group left
.input-group-left .icon-left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 21.2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
  color: rgba(0, 0, 0, 0.6);
}

.input-group-left .MuiInputBase-root {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

//chapter-select

.chapter-select {
  padding: 15px 20px !important;
  font-size: 15px !important;
  display: flex !important;
  justify-content: space-between !important;
  margin: 5px 0px !important;
}

.selected {
  display: flex !important;
  width: 100%;
  .select-icon {
    display: none !important;
  }
}

//video form modal
.video-form-modal {
  justify-content: center;
  align-items: center;
  display: flex;
}
div#demo-simple-select {
  color: white;
}
div#demo-simple-search {
  color: white !important;
}
.otpContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
 
  width: 100%;
  max-width: 20rem;
  gap: 0.75rem;
}

.otpInput {
  width: 2rem !important;
  height: 2rem;
  border-radius: 2rem;
  ;
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  border: 1px solid rgb(101, 102, 105);
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 0.875rem;
  padding: 0.625rem;
  line-height: 1.25rem;
  display: block;
}

.otpInput:focus {
  border: 1px solid rgb(59 130 246);
}